/* AdminDashboard.css */

.admin-dashboard {
  display: flex;
}

.sidebar {
  width: 300px;
  background-color: #050505;
  color: #fff;
  padding: 20px;
  height: 100vh;
  position: relative;
}

nav {
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto; /* Add overflow-y property for vertical scrollbar */
  max-height: 27vw;

}
.nav-link {
  display: flex;
  padding: 18px 37px;
  /* border-bottom: 1px solid #555; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #767676;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 12px;
}
svg{
  margin-right: 5px;
  fill: #767676 !important;
}
  .nav-link:hover {
    color: #fff;
    border-radius: 6px;
    background: #222;
  }
  .nav-link:hover svg{
    margin-right: 5px;
    fill: #ffffff !important;
  }
  .activeWindow svg{
    fill: #fff !important;
    }
    .activeWindow{
    background: #222;
    border-radius: 6px;
    color: #fff;
  }
}
.logo-nav{
  display: flex;
  margin: 0 auto;
  margin-top: 30%;
  justify-content: center;
  margin-bottom: 60px;
  height: 100px;
  width: 100px;
  position: relative;
}
.main-content {
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

.main-content::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
  margin-right: 5px;
}

.main-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #333;
  margin-right: 5px;
}

.cards {
  display: flex;
  gap: 20px;
}

.card {
  /* Add card styling */
}

.profile-section {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 92px 72px 0px 0px;
  z-index: 1;
  /* width: 300px; */
  justify-content: flex-end;
  padding: 7px;
  background: #fff;
  border-radius: 12px;
}

.profile-image {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 12px;
}

.admin-name {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
}
.logout-link {
  cursor: pointer;
  margin-top: auto; /* Push the logout link to the bottom */
  padding: 10px;
  border-top: 1px solid #555;
  display: flex;
  padding: 18px 37px;
  /* border-bottom: 1px solid #555; */
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #767676;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  width: 85%;
  background-color: #000;
}
.logout-link:hover{
  color: #ffffff;
}
.logout-link svg{
  margin-right: 12px;
}
.logout-link:hover svg{
  fill: #fff;
}
/* Webkit Scrollbar Styles */
nav ul::-webkit-scrollbar {
  width: 9px;
  margin-left:10px ;
  border-radius: 15px;
}

nav ul::-webkit-scrollbar-thumb {
  background-color: #FCE6ED;
}

nav ul::-webkit-scrollbar-track {
  background-color: #333;
}
.admin-dashboard .main-content{
  padding: 80px 72px;
}
.admin-dashboard .main-content .cont-wrapper .dashboardText h1{
  color: #383838;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 50% */
  text-transform: capitalize;
  padding-bottom: 19px;
  margin: 0;
}
.admin-dashboard .main-content .cont-wrapper .dashboardText span{
  color: #858585;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 93.333% */
  text-transform: capitalize;
}

.admin-dashboard .main-content .cont-wrapper .cardRow{
  margin-top: 80px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard{
  display: flex;
  width: 400px;
  padding: 24px 24px 25px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 52px;
  border-radius: 12px;
  background: #222;
  color: #fff;
  flex-direction: column;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .topRow, .bottomRow{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .topRow .frsLn span{
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .topRow .frsLn .txtCardIn{
  color: #E7E7E7;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .secsLn{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .frsLn .sec-num{
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .frsLn .sec-num span{
  color: #767676;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .frsLn .txtCardIn{
  color: #E7E7E7;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .secsLn .sec-num{
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .secsLn .sec-num span{
  color: #767676;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .singleCard .bottomRow .secsLn .txtCardIn{
  color: #E7E7E7;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}
.firLinIco{
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #4E4E4E;
  position: relative;
}
.firLinIco svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* white card----------------------------------------------------- */
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard{
  display: flex;
  width: 400px;
  padding: 24px 24px 25px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 52px;
  border-radius: 12px;
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
  color: #000;
  flex-direction: column;
}

.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .topRow, .bottomRow{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .topRow .frsLn span{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .topRow .frsLn .txtCardIn{
  color: #383838;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .secsLn{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .frsLn .sec-num{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .frsLn .sec-num span{
  color: #8b0101;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .frsLn .txtCardIn{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .secsLn .sec-num{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .secsLn .sec-num span{
  color: #767676;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .whiteCard .bottomRow .secsLn .txtCardIn{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}
.whiteCard .firLinIco{
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #FCE6ED;
  position: relative;
}
.firLinIco svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* ultra white card----------------------------------------------------- */
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard{
  display: flex;
  width: 400px;
  padding: 24px 24px 25px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 52px;
  border-radius: 12px;
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
  color: #000;
  flex-direction: column;
}

.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .topRow, .bottomRow{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .topRow .frsLn span{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .topRow .frsLn .txtCardIn{
  color: #383838;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .secsLn{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .frsLn .sec-num{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .frsLn .sec-num span{
  color: #D9D9D9;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .frsLn .txtCardIn{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}



.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .secsLn .sec-num{
  color: #050505;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .secsLn .sec-num span{
  color: #D9D9D9;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: capitalize;
}
.admin-dashboard .main-content .cont-wrapper .cardRow .ultraWhiteCard .bottomRow .secsLn .txtCardIn{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}
.ultraWhiteCard .firLinIco{
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #F4E6FC;
  position: relative;
}
.firLinIco svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* remaining tshirts */
.main-content .cont-wrapper .remTsDetail {
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
  margin-top: 32px;
}
.main-content .cont-wrapper .remTsDetail .rmTMain{
  width: 100%;
}
.main-content .cont-wrapper .remTsDetail .rmTMain .remTMName{
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
  /* padding-top: 32px; */
  padding-bottom: 32px;
  display: block;
}
.main-content .cont-wrapper .remTsDetail .remTmainGraph {
  display: flex;
  width: 100%;
  column-gap: 114px;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph, .right-graph{
  width: 50%;
}
.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #E7E7E7;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail .group{
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail .group span{
  color: #C1C1C1;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail .gsp{
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 100% */
  text-transform: lowercase;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail .gsp span{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: lowercase;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail:first-child{
  padding-top: 0;
}
.main-content .cont-wrapper .remTsDetail .remTmainGraph .left-graph .singleDetail:last-child{
  padding-bottom: 0;
  border: none;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #E7E7E7;
}


.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail .group{
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail .group span{
  color: #C1C1C1;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail .gsp{
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 100% */
  text-transform: lowercase;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail .gsp span{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: lowercase;
}

.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail:first-child{
  padding-top: 0;
}
.main-content .cont-wrapper .remTsDetail .remTmainGraph .right-graph .singleDetail:last-child{
  padding-bottom: 0;
  border: none;
}


/* individual group booking */
.main-content .cont-wrapper .indGrpBookMain{
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  margin-top: 24px;
}
.main-content .cont-wrapper .indGrpBookMain .indBook{
  width: 50%;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #F0F0F0;
  background: #FAFAFA;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .indBookTopic{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-bottom: 32px;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .indBookTopic span{
  display: block;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .indBookTopic:first-child{
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .indBookTopic span:last-child {
  color: #7D7D7D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  text-decoration-line: underline;
  text-transform: capitalize;
  display: block;
  cursor: pointer;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #E7E7E7;
}

.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord:last-child{
  padding-bottom: 0;
  border-bottom: none;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord:first-child{
  padding-top: 0;
}

.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord .dateAndTime{
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: center;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord .dateAndTime .dt{
  color: #4E4E4E;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
  text-transform: capitalize;
  padding-bottom: 4px;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord .dateAndTime .tim{
  color: #9B9B9B;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  text-transform: capitalize;
}

.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 22px;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord .userDtl .userNme{
  display: block;
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
  padding-bottom: 4px;
}
.main-content .cont-wrapper .indGrpBookMain .indBook .singleBookRecord .userDtl .userEml{
  display: block;
  color: #9B9B9B;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  text-transform: lowercase;
}

/* add remove data */
.main-content .cont-wrapper .addRemBtnAdmiin{
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.main-content .cont-wrapper .addRemBtnAdmiin .singleCard{
  width: 20%;
}
.main-content .cont-wrapper .addRemBtnAdmiin .flex-btn{
  display: flex;
  column-gap: 24px;
  justify-content: flex-end;
  width: 80%;
}
.main-content .cont-wrapper .addRemBtnAdmiin .flex-btn .reRec{
  display: flex;
  padding: 15px 37px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  background: #C1C1C1;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}
.main-content .cont-wrapper .addRemBtnAdmiin .flex-btn .addRec{
  display: flex;
  padding: 15px 37px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #E53F71;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}
.main-content .cont-wrapper .addRemBtnAdmiin .flex-btn .addRec svg{
  height: 14px;
  width: 14px;
}

/* individual book */
/* Search Box Styles */
.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}

.search-input {
  position: relative;
}

input[type="text"] {
  display: flex;
  height: 44px;
  padding: 15px 547px 15px 10px;
  /* align-items: center; */
  flex: 1 0 0;
  border: none;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #E7E7E7;
  color: #C1C1C1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

input:focus {
  outline: none;
}
select:focus {
  outline: none;
}

/* Dropdown Styles */
select {
  display: flex;
  height: 45px;
  padding: 15px 15px 15px 17px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  color: #C1C1C1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
  width: 200px;
  cursor: pointer;
}
::placeholder {
  color: #C1C1C1;
}
/* Table Styles */
table {
  width: 100%;
  border-collapse: separate;
  margin-bottom: 20px;
  border-spacing: 0 20px;
  border-radius: 20px;
}

th, td {
  border: none;
  padding: 8px;
  text-align: left;
}
td{
  width: 0;
  padding: 30px 0 30px 30px;
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}
td button {
  text-align: left;
  margin-left: 50%;
}
thead{
  border: none !important;
  margin-bottom: 12px;
}
/* Table Head Styles */
th {
  background-color: #050505;
  color: white;
  border: none;
  padding: 8px;
  text-align: left;
  padding: 30px 0 30px 30px;

}

tr .checkboxId{
  width: 0;
  padding-right: 0;
}

tr {
  border-radius: 12px;
  background: #FAFAFA;
  border-spacing: 0 20px;
}
table tr {
  border-radius: 12px; /* Add border-radius to table rows */
  margin-top: 20px; /* Add a 20px margin-top to table rows */
}
table tr th:first-child{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
table tr th:last-child{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

table tr:first-child{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.search-box span{
  color: #383838;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
  cursor: pointer;
}
.search-box .flex-search{
  display: flex;
  width: 100%;
}
.search-box .flex-search .search-input{
  display: flex;
  height: 44px;
  padding: 15px 547px 15px 0px;
  align-items: center;
  flex: 1 0 0;
  width: 70%;
}

.search-box .flex-search .flex-search-ico{
  display: flex;
  align-items: flex-end;
  align-content: center;
  flex-direction: row;
  width: 30%;
  justify-content: flex-end;
  column-gap: 12px;
}

.search-icon{
  display: flex;
  width: 44px;
  height: 44px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  background: #E53F71;
}
/* Pagination Styles */
button {
  border-radius: 6px;
  border: 1px solid #C1C1C1;
  color: #C1C1C1;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}

button:disabled {
  border-radius: 6px;
  background: #E53F71;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}

.paginationDiv{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
}

.paginationDiv button{
  width: 32px;
  height: 32px;
}


/* add new record css */
.admin-dashboard .main-content .form-container{
  margin-top: 80px;
}
.admin-dashboard .main-content .form-container .frm-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 32px;
  column-gap: 52px;
}
.admin-dashboard .main-content .form-container .frm-row  .one-row{
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  width: 50%;
}

.admin-dashboard .main-content .form-container .frm-row  .one-row label{
  color: #4E4E4E;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
}
.admin-dashboard .main-content .form-container .frm-row  .one-row span{
  color: #A1A1A1;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  margin-bottom: 12px;
}
.admin-dashboard .main-content .form-container .frm-row  .one-row input{
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  display: flex;
  height: 44px;
  padding: 15px 20px 15px 10px;
  /* align-items: center; */
  flex: 1 0;
  color: #C1C1C1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.admin-dashboard .main-content .form-container .frm-row  .contact-row{
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  width: 30%;
}
.admin-dashboard .main-content .form-container  .contact-row .one-row{
  width: fit-content;
}
.admin-dashboard .main-content .form-container .contact-row  .one-row input{
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  display: flex;
  height: 44px;
  padding: 15px 280px 15px 10px;
  /* align-items: center; */
  flex: 1 0;
  color: #C1C1C1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.termsandCondition label{
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}
.termsandCondition label input[type="checkbox"]{
  margin-top: 6px;
}
.termsandCondition label span{
  color: #6C6C6C;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 155.556% */
  margin-bottom: 32px;
}
.onFormCancle{
  display: flex;
  padding: 15px 37px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.buttin-set-sub{
  display: flex;
  column-gap: 24px;
}
.onFormCancle{
  display: flex;
  padding: 15px 37px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #C1C1C1;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  border: none;
  text-transform: capitalize;
}
.onFormSubmit{
  display: flex;
  padding: 15px 37px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: #E53F71;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
  border: none;
}

.admin-dashboard .main-content .checkout-main-wrapper{
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  column-gap: 24px;
}
.admin-dashboard .main-content .checkout-main-wrapper .leftSide-check{
  width: 50%;
  padding: 40px;
  border-radius: 6px;
  background: #F9F9F9;
}
.personDetail .lineSingle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.personDetail .lineSingle span{
  display: block;
}
.personDetail .lineSingle span:first-child{
  color: #393939;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
}
.personDetail .lineSingle span:last-child{
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 107.692% */
}
.personDetail .lineSingle:last-child{
  padding-bottom: 24px;
  border-bottom: 2px solid #E7E7E7;
  margin-bottom: 24px;
}

.tSize .lineSingle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tSize .lineSingle span{
  display: block;
}
.tSize .lineSingle span:first-child{
  color: #7D7D7D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}
.tSize .lineSingle span:last-child{
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}
.tSize .lineSingle:last-child{
  padding-bottom: 24px;
  border-bottom: 2px solid #E7E7E7;
  margin-bottom: 24px;
}

.registData .lineSingle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.registData .lineSingle span{
  display: block;
}
.registData .lineSingle span:first-child{
  color: #7D7D7D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}
.registData .lineSingle span:last-child{
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}
.registData .lineSingle:last-child{
  padding-bottom: 24px;
  border-bottom: 2px solid #E7E7E7;
  margin-bottom: 24px;
}

.totDonation .lineSingle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.totDonation .lineSingle span{
  display: block;
}
.totDonation .lineSingle span:first-child{
  color: #E53F71;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 77.778% */
}
.totDonation .lineSingle span:last-child{
  color: #E54E7B;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 77.778% */
}
.admin-dashboard .main-content .checkout-main-wrapper .rgtSide-check{
  width: 50%;
  padding: 0px;
  border-radius: 6px;
}
.cardInCashMain .flx-crd{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background: #F7F7F7;
}
.cardInCashMain .flx-crd .lft{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.cardInCashMain .flx-crd .lft span:first-child{
  display: block;
  color: #282828;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 87.5% */
  margin-bottom: 12px;
}
.cardInCashMain .flx-crd .lft span:last-child{
  display: block;
  color: #929292;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.payOptions{
  margin-top: 24px;
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 6px;
  background: #F5F5F5;
}


.payOptions .payOptionline{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 46px;
  align-items: center;
  color: #A1A1A1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.para-def-pay p{
  color: #A1A1A1;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-top: 24px;
  margin-bottom: 24px;
}
.payButtonCheckout{
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: #E53F71;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  width: 100%;
  border: none;
  transition: all 0.5s;
}

.payButtonCheckout:hover{
  background: #3c2f33;
  transition: all 0.5s;
}

.popupWindow{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  padding: 40px 32px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 12px;
  background: #FFF;
  width: 480px;
}
.popupWindow .popup-content img{
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
}
.popUpThanks{
  color: #393939;
  text-align: center;
  display: block;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 83.333% */
  text-transform: capitalize;
  margin-bottom: 12px;
}
.tagThanks{
  color: #7D7D7D;
  text-align: center;
  display: block;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  margin-bottom: 40px;
}
.spcTag{
  color: #808080;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  width: fit-content;
  margin: 0 auto;
  display: block;
  margin-bottom: 24px;
}
.popup-content p{
  color: #A1A1A1;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.sendPop{
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #E53F71;
  width: 100%;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
}

/* end individual booking UIs---------------------------------------------- */
/* start group booking UIs */


.admin-dashboard .main-content .form-container .halfRow{
  width: 50%;
  padding-right: 2%;
  column-gap: 24px;
}
.admin-dashboard .main-content .form-container .toggleButton{
  display: flex;
  width: 100%;
  padding: 16px 24px;
  align-items: center;
  gap: 789px;
  border-radius: 6px;
  background: #F4E6FC;
  color: #A83FE5;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
  border: none;
  margin-bottom: 24px;
  justify-content: space-between;
}
.admin-dashboard .main-content .form-container .contentToToggle{
  margin-bottom: 24px;
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #D7D7D7;
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row .top-rw{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
  align-items: center;
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row .top-rw:last-child {
  padding-bottom: 0;
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row .top-rw .lft-tg{
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row .top-rw .rgt-tg{
  color: #7D7D7D;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 107.692% */
}
.admin-dashboard .main-content .form-container .contentToToggle .tg-row input[type="number"]{
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 42px;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  color: #7D7D7D;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  text-transform: capitalize;
  width: 120px;
}

.tLableTxt{
  display: flex;
  padding: 20px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background-color: rgba(168, 63, 229, 0.285);
  margin-bottom: 20px;
}
.tLableTxt span{
  color: #A83FE5;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 87.5% */
  text-transform: capitalize;
}
.grpCat .lineSingle span:first-child{
  color: #7D7D7D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}
.grpCat .lineSingle span:last-child {
  color: #383838;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}

.quntiLbl .lineSingle span:first-child{
  color: #7D7D7D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}

.quntiLbl .lineSingle span:last-child{
  color: #7D7D7D;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
}

.tDatalbls .lineSingle span:first-child{
  color: #383838;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}

.tDatalbls .lineSingle span:last-child{
  color: #383838;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}

.donGrp .lineSingle span:first-child{
  color: #A83FE5;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 77.778% */
}

.donGrp .lineSingle span:last-child{
  color: #A83FE5;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 77.778% */
}
.payGrpCheckout{
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: #A83FE5;;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  width: 100%;
  border: none;
  transition: all 0.5s;
}

.payGrpCheckout:hover{
  background: #3c2f33;
  transition: all 0.5s;
}

.sendGrpPop{
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #A83FE5;;
  width: 100%;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
}
.editBtn{
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 6px;
  background: #E53F71;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-transform: capitalize;
  border: none;
}

/* styles.css */

.image-upload {
  display: flex;
  height: 212px;
  padding: 73px 160px 72px 161px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  display: flex;
  position: relative;
}
.custom-file-upload-btn{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.custom-file-upload-btn svg{
  margin-bottom: 12px;
}
.admin-dashboard .main-content .form-container .frm-row .one-row .custom-file-upload-btn .dragUpload{
  color: #D7D7D7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 85.714% */
  text-transform: capitalize;
  margin-bottom: 12px;
  display: block;
}
.admin-dashboard .main-content .form-container .frm-row .one-row .custom-file-upload-btn .browse{
  color: #FF749E;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  text-transform: capitalize;
  display: block;
}

.nav-link > svg, .nav-link > img {
  margin-right: 15px;
}

nav > ul > li > .nav-link, nav > ul > li > .nav-link:focus {
  color: #767676;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

nav > ul > li > .nav-link.active, nav > ul > li > .nav-link:hover {
  background-color: #222222;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.user-leaderboard-user-list {
  gap: 12px;
}

.user-leaderboard-user-list > .col {
  background-color: white;
  border-radius: 6px;
  padding: 16px;
  width: calc(25% - 12px);
  cursor: pointer;
}

.user-leaderboard-user-list > .col > div {
  display: flex;
  align-items: center;
}

.user-leaderboard-user-list > .col > div:first-child {
  padding-bottom: 12px;
  border-bottom: 1px solid #E7E7E7;
}

.user-leaderboard-user-list > .col > div:last-child {
  padding-top: 12px;
}

.user-leaderboard-user-list > .col > div > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 22px;
}

.user-leaderboard-user-list > .col > div > .user-detail-container {
  width: 100%;
  text-align: right;
  overflow-wrap: anywhere;
}

.user-leaderboard-user-list > .col > div > .user-detail-container > .user-name {
  display: block;
  color: #4E4E4E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: capitalize;
  padding-bottom: 4px;
}

.user-leaderboard-user-list > .col > div > .user-detail-container > .user-email {
  display: block;
  color: #9B9B9B;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: lowercase;
}

@media (max-width: 1800px) {
    td button {
        margin-left: 35%;
    }
}

@media (max-width: 1650px) {
  .user-leaderboard-user-list > .col {
    width: calc((100% / 3) - 12px);
  }
}

@media (max-width: 1550px) {
    td button {
        margin-left: 25%;
    }
}

@media (max-width: 1400px) {
    .user-leaderboard-user-list > .col {
        width: calc(50% - 12px);
    }

    td button {
        margin-left: 0;
    }
}

@media (max-width: 1050px) {
    .user-leaderboard-user-list > .col {
        width: calc(100% - 12px);
    }
}


