body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-file-label {
    background-color: #EEEEEE;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 25vh;
    border-radius: 0;
    margin-left: 0;
    padding: 5%;
    text-align: center;
}

.custom-file-input {
    z-index: 1 !important;
}

.custom-file > img, .custom-file > video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
    z-index: 1;
}

.custom-file > img:hover ~ .custom-file-label,
.custom-file > video:hover ~ .custom-file-label,
.custom-file-input:hover ~ .custom-file-label {
    z-index: 2;
    opacity: 0.5;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: transparent !important;
    box-shadow: none !important;
}
